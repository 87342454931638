import Layout from "@/layout";
import React from "react";
import Seo from "@components/head";
import BlokContainer from "@/components/blok-container";
import {
  Box,
  Center,
  Grid,
  GridItem,
  Image,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react";
import CommonParagraph from "@/components/common-paragraph";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import * as styles from "./index.module.less";

const dataAppSecurity = [
  {
    title: "Data Center",
    desc: (
      <Center flexDir="column">
        <Text as="p" color="#5B5171">
          Airgram customer data is hosted by Amazon Web Services (AWS) in the
          United States, which is a robust security infrastructure certified by
          SOC 2 Type II. AWS infrastructure provides a reliable, scalable, and
          secure environment for storing data in the cloud.
        </Text>
        <CommonParagraph>
          More can be learned about AWS security{" "}
          <ChakraLink
            href="https://aws.amazon.com/security/"
            rel="nofollow"
            target="_blank"
            color="#9F2AEC"
          >
            here
          </ChakraLink>
          .
        </CommonParagraph>
      </Center>
    ),
  },
  {
    title: "Backup of data",
    desc: (
      <Center flexDir="column">
        <Text as="p" color="#5B5171">
          Airgram backs up data daily on its system using AWS and maintains
          backups for a period of 7 days.
        </Text>
        <CommonParagraph>
          Data stored in AWS S3 is distributed across three availability zones,
          which helps to improve the reliability and availability of data
          through decentralized storage management.
        </CommonParagraph>
      </Center>
    ),
  },
  {
    title: "Encryption",
    desc: (
      <Center flexDir="column">
        <Text as="p" color="#5B5171">
          Airgram encrypts all data in transit between a web browser and a
          server using HTTPS/TLS, ensuring that sensitive information is kept
          private and secure.
        </Text>
        <CommonParagraph>
          Furthermore, all data is encrypted at rest using AES 128-bit
          encryption.
        </CommonParagraph>
      </Center>
    ),
  },
];

const Security = () => {
  return (
    <Layout pt="0px" px="0px" margin="0px" maxW="100%">
      <Seo
        title="Airgram | Security Policy"
        description="Airgram complies with international standards and adopts industry best practices to ensure our system and user data stay secure."
      />
      {/* Hero banner */}
      <Center
        w="full"
        padding={{
          base: "80px 24px",
          sm: "80px 40px",
          md: "80px 96px",
          xl: "120px 96px",
          "2xl": "160px 96px",
        }}
        className={styles.hero_banner}
      >
        <Center flexDir="column" gap="24px" maxW="860px">
          <Text as="h1" textAlign="center">
            Security Policy
          </Text>
          <CommonParagraph color="#5B5171">
            Keeping our clients' data secure is an absolute top priority at
            Airgram. We comply with international standards and adopt industry
            best practices to ensure our system stay secure.
          </CommonParagraph>
          <CommonParagraph>Last updated on Feb 23, 2023</CommonParagraph>
        </Center>
      </Center>
      {/* Compliance */}
      <BlokContainer blockTitle="Compliance">
        <Center
          gap={{
            base: "24px",
            sm: "40px",
          }}
          flexDir={{
            base: "column",
            lg: "row",
          }}
          maxW={{
            base: "300px",
            sm: "400px",
            lg: "100%",
          }}
          w="full"
        >
          <Center
            bg="#FBFBFD"
            w="full"
            maxW="520px"
            h={{
              base: "210px",
              sm: "300px",
              lg: "320px",
              xl: "390px",
            }}
            borderRadius={{
              base: "16px",
              sm: "20px",
              xl: "26px",
            }}
          >
            <Image
              w={{
                base: "120px",
                sm: "170px",
                lg: "190px",
                xl: "220px",
              }}
              h={{
                base: "120px",
                sm: "170px",
                lg: "190px",
                xl: "220px",
              }}
              className="lozad"
              src={LOADING_IMAGE_URL}
              data-src="https://a.storyblok.com/f/167495/x/b412a70a00/loog-aicpa-soc.svg"
            />
          </Center>
          <Center gap="24px" flexDir="column" w="full">
            <Text as="h4">SOC 2 Type II</Text>
            <CommonParagraph>
              Airgram Inc. has achieved audit certification for Service
              Organization Controls (SOC 2) since 2022.
            </CommonParagraph>
            <CommonParagraph>
              <ChakraLink
                rel="nofollow"
                target="_blank"
                color="#9F2AEC"
                href="https://us.aicpa.org/interestareas/frc/assuranceadvisoryservices/aicpasoc2report"
              >
                SOC 2
              </ChakraLink>{" "}
              is a type of audit report that evaluates an organization's
              controls related to security, availability, processing integrity,
              confidentiality, and privacy. Our continued SOC compliance
              indicates a high level of security and ensures client data is
              being handled properly.
            </CommonParagraph>
            <CommonParagraph>
              If you want to check Airgram’s latest report, please contact us at{" "}
              <ChakraLink color="#9F2AEC" href="mailto:support@airgram.io">
                support@airgram.io
              </ChakraLink>
              .
            </CommonParagraph>
          </Center>
        </Center>
      </BlokContainer>
      {/* Data and Application Security */}
      <BlokContainer
        blockTitle="Data and Application Security"
        blockBg="#FBFBFD"
      >
        <Grid
          gridTemplateColumns={{
            base: "1fr",
            xl: "1fr 1fr 1fr",
          }}
          gap={{
            base: "24px",
            sm: "40px",
          }}
        >
          {dataAppSecurity.map((item, index) => {
            return (
              <GridItem key={index}>
                <Center
                  flexDir="column"
                  bg="#fff"
                  padding={{
                    base: "24px",
                    sm: "40px",
                  }}
                  borderRadius="30px"
                  h="full"
                  justifyContent="flex-start"
                  gap="12px"
                >
                  <Text as="h4">{item.title}</Text>
                  {item.desc}
                </Center>
              </GridItem>
            );
          })}
        </Grid>
      </BlokContainer>
      {/* Vulnerability Management & Personnel Security */}
      <BlokContainer>
        <Center flexDir="column">
          <Center
            w="full"
            flexDir="column"
            gap={{
              base: "40px",
              lg: "80px",
            }}
          >
            <Center
              flexDir={{
                base: "column",
                lg: "row",
              }}
              gap="40px"
              alignItems="flex-start"
            >
              {/* content */}
              <Center
                flex={1}
                flexDir="column"
                gap={{ base: "12px", lg: "24px" }}
              >
                <Text as="h2">Vulnerability Management</Text>
                <Center gap="8px" flexDir="column">
                  <CommonParagraph>
                    Airgram runs automated security testing on an ongoing basis
                    for potential threats and promptly addressing any
                    vulnerabilities that are identified. Server status is
                    updated every minute to ensure uptime at all times.
                  </CommonParagraph>
                  <CommonParagraph>
                    Additionally, penetration tests are executed at least once a
                    year to make sure our software remains secure.
                  </CommonParagraph>
                </Center>
              </Center>
              {/* Image */}
              <Center
                w={{
                  base: "full",
                  lg: "auto",
                }}
                alignSelf="center"
              >
                <Image
                  w={{
                    base: "200px",
                    lg: "258px",
                  }}
                  h={{
                    base: "150px",
                    lg: "193px",
                  }}
                  borderRadius="16px"
                  className="lozad"
                  objectFit="cover"
                  src={LOADING_IMAGE_URL}
                  data-src="https://a.storyblok.com/f/167495/516x386/124bd5749e/vulnerability-management.png"
                />
              </Center>
            </Center>
            <Center
              flexDir={{
                base: "column",
                lg: "row",
              }}
              gap="40px"
              alignItems="flex-start"
            >
              <Center
                flexDir="column"
                gap={{ base: "12px", lg: "24px" }}
                flex={1}
              >
                <Text as="h2">Personnel Security</Text>
                <Center gap="8px" flexDir="column">
                  <CommonParagraph>
                    All Airgram employees and service providers must sign
                    confidentiality and non-disclosure agreements to ensure that
                    all information collected on our systems is kept
                    confidential.
                  </CommonParagraph>
                  <CommonParagraph>
                    Moreover, our customer support personnel will only access
                    customer information (and ask for permission beforehand) for
                    the purpose of troubleshooting. Such access is monitored by
                    internal security team.
                  </CommonParagraph>
                  <CommonParagraph>
                    The security team also maintains a company-wide training
                    program annually to increase staff’s security awareness and
                    further reduce the risk of information leakage.
                  </CommonParagraph>
                </Center>
              </Center>
              <Center
                w={{
                  base: "full",
                  lg: "auto",
                }}
                alignSelf="center"
              >
                <Image
                  w={{
                    base: "200px",
                    lg: "258px",
                  }}
                  h={{
                    base: "150px",
                    lg: "193px",
                  }}
                  className="lozad"
                  borderRadius="16px"
                  objectFit="cover"
                  src={LOADING_IMAGE_URL}
                  data-src="https://a.storyblok.com/f/167495/516x386/deb61120f4/personnel-security.png"
                />
              </Center>
            </Center>
          </Center>
          <Box
            mt={{ base: "40px", lg: "60px" }}
            borderTop="1px solid #C5CDF9"
            pt={{
              base: "40px",
              lg: "60px",
            }}
          >
            <CommonParagraph>
              We aim for data security and satisfying user experience. To report
              a vulnerability or other security concern, please email us at{" "}
              <ChakraLink color="#9F2AEC" href="mailto:support@airgram.io">
                support@airgram.io
              </ChakraLink>{" "}
              or directly message us via Intercom.
            </CommonParagraph>
          </Box>
        </Center>
      </BlokContainer>
    </Layout>
  );
};

export default Security;
