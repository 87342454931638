import React, { ReactNode } from "react";
import { Text } from "@chakra-ui/react";

interface IProp {
  children?: string | ReactNode;
  color?: string | object;
}

const CommonParagraph = ({ children, color = "#5B5171" }: IProp) => {
  return (
    <Text color={color} as="p" style={{ margin: "0px" }}>
      {children}
    </Text>
  );
};

export default CommonParagraph;
